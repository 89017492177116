<template>
  <div class="newAll">
    <el-container>
      <el-header style="height: 0px; padding: 0px"> </el-header>
      <el-main>
        <el-backtop :visibility-height="700" :bottom="100"></el-backtop>
        <div class="alls">
          <div class="alls">
            <div :class="showNav == true ? 'first' : 'newFirst'">
              <div class="firstLeft">
                <img
                  src="../assets/img/logo/logo.png"
                  alt=""
                  @click="jumpIndex"
                />
                <h2>御之安科技</h2>
              </div>
              <div class="firstMiddle">
                <el-menu
                  ref="elmenudemo"
                  mode="horizontal"
                  :default-active="$route.path"
                  :router="true"
                  class="el-menu-demo"
                  @select="handleSelect"
                  background-color="#F0F0F0"
                  text-color="#000"
                  active-text-color="#2a6fa5"
                >
                  <el-menu-item index="/"> 首页</el-menu-item>
                  <el-submenu index="2">
                    <template slot="title">安全产品</template>
                    <el-menu-item index="/pc/mappingCenter" style="width: 220px"
                      >虚影-数据隐蔽防御系统</el-menu-item
                    >
                    <el-menu-item index="/pc/ctfCenter" style="width: 220px"
                      >御之安CTF竞赛平台</el-menu-item
                    >
                    <el-menu-item index="/pc/redBlueCenter" style="width: 220px"
                      >御之安知识培训平台</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/vulnerabilityCenter"
                      style="width: 220px"
                      >御之安Windows靶场平台</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="3">
                    <template slot="title">安全服务</template>
                    <el-menu-item
                      index="/pc/fencingOperations"
                      style="padding-left: 20px"
                    >
                      护网行动</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/safetyCompetition"
                      style="padding-left: 20px"
                    >
                      安全竞赛</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/codeAudit"
                      style="padding-left: 20px"
                      >代码审计</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/emergencyResponse"
                      style="padding-left: 20px"
                      >应急响应</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/securityOperation"
                      style="padding-left: 20px"
                      >安全运维</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/securityReinforcement"
                      style="padding-left: 20px"
                      >安全加固</el-menu-item
                    >
                    <!-- <el-menu-item index="3-8" style="padding-left: 20px"
                      >风险评估</el-menu-item
                    > -->
                    <el-menu-item
                      index="/pc/securityAdvisory"
                      style="padding-left: 20px"
                      >安全咨询</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="4">
                    <template slot="title">赛事服务</template>
                    <el-menu-item index="4-1" style="padding-left: 20px"
                      >比赛入口</el-menu-item
                    >
                    <el-menu-item index="4-2" style="padding-left: 20px"
                      >赛事承办</el-menu-item
                    >
                    <el-menu-item index="4-2" style="padding-left: 20px"
                      >赛事复盘</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="5">
                    <template slot="title">校企合作</template>
                    <el-menu-item
                      index="/pc/safetyTraining"
                      style="padding-left: 20px"
                      >安全培训</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/platformEquipment"
                      style="padding-left: 20px"
                      >平台设备</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="6">
                    <template slot="title">关于御之安</template>
                    <el-menu-item
                      index="/pc/companyProfile"
                      style="padding-left: 20px"
                      >公司介绍</el-menu-item
                    >
                    <el-menu-item index="/pc/joinUs" style="padding-left: 20px"
                      >加入我们</el-menu-item
                    >
                    <el-menu-item
                      index="/pc/contactUs"
                      style="padding-left: 20px"
                      >联系我们</el-menu-item
                    >
                  </el-submenu>
                  <!-- <el-menu-item index="/pc/aboutUs">关于御之安</el-menu-item> -->
                </el-menu>
              </div>
              <!-- <div class="firstLeft11">
                <el-button type="primary">点击切换中英文</el-button>
              </div> -->
            </div>
          </div>

          <el-dialog
            :close-on-click-modal="false"
            style="text-align: left"
            :visible.sync="popup"
            width="30%"
          >
            <div class="form-layer">
              <el-form label-width="100px" size="mini">
                <el-form-item label="获取定位">
                  <el-button type="primary" @click="fixedPos"
                    >重新定位</el-button
                  >
                </el-form-item>
                <el-form-item label="当前纬度">
                  <p>{{ latitude }}</p>
                </el-form-item>
                <el-form-item label="当前经度">
                  <p>{{ longitude }}</p>
                </el-form-item>
                <el-form-item>
                  <div class="f-a-c">
                    <el-input
                      v-model="keyWords"
                      placeholder="请输入地区"
                      style="width: 230px; margin-right: 6px"
                    ></el-input>
                    <el-button
                      type="primary"
                      @click="setPlace"
                      :disabled="!keyWords"
                      >查询</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
              <div id="map"></div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button size="small" type="primary" @click="btnSubmit()"
                >确 认</el-button
              >
              <el-button size="small" @click="popup = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-main>
      <el-footer style="height: 0px; margin-top: auto">
        <div class="lasts">
          <h2>御之安</h2>
          <div class="lastsTop">
            <div class="lastsTop1">
              <h3>联系地址:</h3>
              <p>地址:中国(四川)成都武侯区天府三街新希望国际大厦B2座2319</p>
              <p>邮箱:admin@yuzhian.com.cn</p>
              <p>电话:+86 18381475890,+86 18770382226</p>
            </div>
            <div class="lastsTop3">
              <h3>产品中心:</h3>
              <p>
                <router-link to="/pc/mappingCenter"
                  >虚影-数据隐蔽防御系统 </router-link
                >
              </p>
              <p>
                <router-link to="/pc/ctfCenter">御之安CTF竞赛平台</router-link>
              </p>
              <p>
                <router-link to="pc/redBlueCenter"
                  >御之安知识培训平台</router-link
                >
              </p>
              <p>
                <router-link to="/pc/vulnerabilityCenter"
                  >御之安Windows靶场平台</router-link
                >
              </p>
            </div>
            <div class="lastsTop2">
              <h3>安全服务:</h3>
              <p><router-link to="/pc/safetyTraining">安全培训</router-link></p>
              <p><router-link to="/pc/codeAudit">代码审计</router-link></p>
              <p>
                <router-link to="/pc/emergencyResponse">应急响应</router-link>
              </p>
              <p>
                <router-link to="/pc/safetyCompetition">安全竞赛</router-link>
              </p>
              <p>
                <router-link to="/pc/securityReinforcement"
                  >安全加固</router-link
                >
              </p>
              <p>
                <router-link to="/pc/securityAdvisory">安全咨询</router-link>
              </p>
            </div>
            <div class="lastsTop2">
              <h3>校企合作:</h3>
              <p><router-link to="/pc/safetyTraining">安全培训</router-link></p>
              <p>
                <router-link to="/pc/platformEquipment">平台设备</router-link>
              </p>
            </div>
            <div class="lastsTop2">
              <h3>关于御之安:</h3>
              <p><router-link to="/pc/companyProfile">关于我们</router-link></p>
              <p><router-link to="/pc/joinUs">加入我们</router-link></p>
              <p><router-link to="/pc/contactUs">联系我们</router-link></p>
            </div>
            <div class="lastsTop6">
              <h3>企业微信:</h3>
              <img src="../assets/img/logo/wechat.jpg" alt="" />
              <p>公众号:</p>
              <img src="../assets/img/logo/wechat1.jpg" alt="" />
            </div>
          </div>
          <div class="lastsTop7">
            <ul>
              <li>
                <router-link to="/pc/legalStatement">法律声明</router-link>
              </li>
              <li>
                <router-link to="/pc/intellectualPropertyDescription"
                  >知识产权声明</router-link
                >
              </li>
              <li>
                <router-link to="/pc/privacyProtection">隐私保护</router-link>
              </li>
            </ul>
          </div>
          <div class="lastsMiddle">
            <router-link to="/">@2021成都御之安科技有限公司</router-link>
            &nbsp;&nbsp;<a href="http://beian.miit.gov.cn/"
              >蜀ICP备2022013491号-1</a
            >&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              >地址:中国(四川)自由贸易试验区成都高新区新希望国际B2座2319</span
            >
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      isCollapse: true,
      activeName: "first",
      activeIndex2: "",
      wechatShow: false,
      bannerList: [
        require("../assets/img/banner/banner-bg-4.jpg"),
        require("../assets/img/banner/banner-bg-5.jpg"),
        require("../assets/img/banner/footer-bg.jpg"),
      ],
      wechatShow: false,
      showNav: true,
      map: null,
      local: null,
      mk: null,
      latitude: "",
      longitude: "",
      keyWords: "新希望国际B座",
      popup: false,
      classOption: {
        limitMoveNum: 2,
        direction: 3,
        step: 0.5,
      },
      classOption1: {
        limitMoveNum: 2,
        direction: 2,
        step: 0.5,
      },
      indexShow: true,
      keys: "", //为了首页
    };
  },

  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollLength, false);
    // this.$nextTick(() => {
    //   // console.log(this.$refs.elmenudemo);
    //   // if (this.$refs.elmenudemo[0]) {
    //   //   this.$refs.elmenudemo[0].$el.click();
    //   // }
    //   this.$emit('handleSelect', this.keys)
    // });
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.scrollLength);
  },
  created() {},
  methods: {
    jumpIndex() {
      this.$router.push("/");
    },
    scrollLength() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const top = document.documentElement.scrollTop;
      if (top > 122.55) {
        let opacity = top / 100;
        opacity = opacity > 1 ? 1 : opacity;
        this.showOpacity = { opacity };
        this.showNav = false;
      }
      if (top < 100) {
        this.showNav = true;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.keys = "/pc/indexs";
      // if (this.keys !== "/") {
      //   this.indexShow = false;
      // }
      // if (this.keys == "/") {
      //   this.indexShow = true;
      // }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 打开弹窗，name为弹窗名称
    async openDialog() {
      this.popup = true;
      this.initMap();
    },
    // 确认
    btnSubmit() {
      let key = {
        latitude: this.latitude,
        longitude: this.longitude,
      };
      // 打印经纬度
      console.log(key);
      this.popup = false;
    },
    initMap() {
      console.log(new window.BMap.Map("map"));
      this.$nextTick(() => {
        this.map = new window.BMap.Map("map");
        let point = new window.BMap.Point(30.552246, 104.072747);
        this.map.centerAndZoom(point, 12);
        this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
        this.map.addControl(new window.BMap.NavigationControl());
        this.fixedPos();
      });
    },
    // 点击定位-定位到当前位置
    fixedPos() {
      const _this = this;
      const geolocation = new window.BMap.Geolocation();
      this.confirmLoading = true;
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          _this.handleMarker(_this, r.point);
          let myGeo = new window.BMap.Geocoder();
          myGeo.getLocation(
            new window.BMap.Point(r.point.lng, r.point.lat),
            function (result) {
              _this.confirmLoading = false;
              if (result) {
                _this.latitude = result.point.lat;
                _this.longitude = result.point.lng;
              }
            }
          );
        } else {
          _this.$message.error("failed" + this.getStatus());
        }
      });
    },
    // 搜索地址
    setPlace() {
      this.local = new window.BMap.LocalSearch(this.map, {
        onSearchComplete: this.searchPlace,
      });
      this.local.search(this.keyWords);
    },
    searchPlace() {
      if (this.local.getResults() != undefined) {
        this.map.clearOverlays(); //清除地图上所有覆盖物
        if (this.local.getResults().getPoi(0)) {
          let point = this.local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
          this.map.centerAndZoom(point, 18);
          this.handleMarker(this, point);
          console.log("经度：" + point.lng + "--" + "纬度" + point.lat);
          this.latitude = point.lat;
          this.longitude = point.lng;
        } else {
          this.$message.error("未匹配到地点!");
        }
      } else {
        this.$message.error("未找到搜索结果!");
      }
    },
    // 设置标注
    handleMarker(obj, point) {
      let that = this;
      obj.mk = new window.BMap.Marker(point);
      obj.map.addOverlay(obj.mk);
      obj.mk.enableDragging(); // 可拖拽
      obj.mk.addEventListener("dragend", function (e) {
        // 监听标注的拖拽，获取拖拽后的经纬度
        that.latitude = e.point.lat;
        that.longitude = e.point.lng;
      });
      obj.map.panTo(point);
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  /*其他*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/deep/.el-backtop {
  width: 70px !important;
  height: 70px !important;
  font-size: 2.5rem !important;
}
/deep/.el-main {
  padding: 0px !important;
}
.newAll {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .alls {
    width: 100%;

    .first {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #f0f0f0;
      .firstLeft {
        width: 30%;
        display: flex;
        align-items: center;
        img {
          width: 55px;
          height: 55px;
        }
        h2 {
          margin-left: 10px;
        }
      }
      .firstMiddle {
        font-size: 1rem !important;
        // margin-left: 300px;
        /deep/#newElMenu {
          width: 220px !important;
        }
        /deep/.el-menu-item {
          // font-size: 1.25rem;
        }
        /deep/.el-submenu__title {
          padding: 0 20px !important;
          // font-size: 1.25rem;
        }
        /deep/.el-menu
          .el-menu--popup
          .el-menu--popup-bottom-start
          .el-menu-item {
          font-size: 1.5rem !important;
        }
      }
    }
    .newFirst {
      width: 100%;
      display: flex;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99 !important;
      position: fixed;
      background-color: #f0f0f0;
      box-shadow: 0 2px 2px 0 rgb(233, 230, 230);
      justify-content: space-around;
      align-items: center;
      transition: all 0.5s;
      .firstLeft {
        width: 30%;
        display: flex;
        align-items: center;
        img {
          width: 55px;
          height: 55px;
        }
        h2 {
          margin-left: 10px;
        }
      }
      .firstMiddle {
        font-size: 1rem !important;
        // margin-left: 300px;
        // background-color: #484a4e;
        /deep/#newElMenu {
          width: 220px !important;
        }
        /deep/.el-menu-item {
          // font-size: 1.25rem;
        }
        /deep/.el-submenu__title {
          padding: 0 20px !important;
          // font-size: 1.25rem;
        }
        /deep/.el-menu
          .el-menu--popup
          .el-menu--popup-bottom-start
          .el-menu-item {
          font-size: 1.5rem !important;
        }
      }
    }
    .second {
      width: 100%;
      height: 480px;
      overflow: hidden;
      img {
        width: 100%;
        height: 480px;
      }
    }
    .third {
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      p {
        margin-bottom: 35px;
        font-size: 22px;
        text-align: center;
      }
      .thirdBtns:hover {
        box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
        transform: translate(0, -10px);
      }
    }
    .fourth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      .fourth1 {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .box {
          width: 240px;
          margin-top: 20px;
          position: relative;
          transition: all 1s;
          margin-left: 20px;
          transform-style: preserve-3d;
        }
        .front,
        .back {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        //正面
        .front {
          width: 240px;
          height: 200px;
          background-color: #fff;
          z-index: 1;
          backface-visibility: hidden;
          box-shadow: -1px 17px 27px 1px #f0f2f4;
          border-radius: 6px;
          opacity: 1;
          //标题
          .front_title {
            height: 233px;
            display: flex;
            align-items: center;
            justify-content: center;
            ._title_i {
              width: 50px;
              height: 50px;
              margin-right: 18px;
            }
            ._title_p {
              font-size: 30px;
              font-weight: bold;
              color: #515151;
            }
          }
          .front_text {
            position: absolute;
            margin: 0 auto;
            width: 440px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .text_p {
              width: 360px;
              height: 111px;
              font-size: 22px;
              color: #969696;
            }
          }
        }
        //反面
        .back {
          width: 240px;
          height: 200px;
          background: #04040e;
          transform: rotateY(180deg);
          padding: 10px;
          font-size: 16px;
          color: #fff;
          transform-style: preserve-3d;
          border-radius: 8px;
          position: relative;
          //标题
          .back_title {
            height: 174px;
            display: flex;
            align-items: center;
            justify-content: center;
            ._title_itwo {
              font-size: 88px;
              margin-right: 40px;
            }
            ._title_ptwo {
              font-size: 30px;
              font-weight: bold;
              color: #f5f5f5;
              border-radius: 4px;
            }
          }

          //内容
          .back_contentTwo {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 18px;
            //模板
            .contentTwoIconModules {
              width: 112px;
              height: 101px;
              text-align: center;
              .TwoIconModules_i {
                font-size: 40px;
                color: #f5f5f5;
                margin-bottom: 10px;
              }
              .TwoIconModules_p1 {
                width: 122px;
                height: 21px;
                font-size: 16px;
                color: #f5f5f5;
                margin-bottom: 8px;
              }
              .TwoIconModules_p2 {
                font-size: 14px;
                font-weight: bold;
                color: #f5f5f5;
              }
            }
          }

          //背景图片
          .contentTwoImgModules {
            width: 220px;
            height: 210px;
            position: absolute;
            right: 0%;
            bottom: 0%;
          }
        }
        .box:hover {
          transform: rotateY(180deg);
        }
      }
    }
    .fifth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      .fifth1 {
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .fifth1Left {
        width: 20%;
        img {
          width: 355px;
          height: 600px;
        }
      }
      .fifth1Right {
        width: 60%;
        margin-left: 30px;
        .fifth1Right1 {
          color: #e21f36;
          font-weight: 600;
          padding-bottom: 10px;
          border-bottom: 1px solid #515151;
        }
        h2 {
          font-size: 35px;
          margin-bottom: 40px;
          position: relative;
          font-weight: 600;
        }
        /deep/.el-tabs__item {
          padding: 0 27px;
          height: 40px;
          box-sizing: border-box;
          line-height: 40px;
          display: inline-block;
          list-style: none;
          font-size: 20px;
          font-weight: 500;
          color: #303133;
          position: relative;
        }
        .fifth1Right2 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
        .fifth1Right3 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
        .fifth1Right4 {
          color: #6e727f;
          font-size: 19px;
          margin-top: 10px;
        }
      }
    }
    .sixth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .sixth1 {
        width: 65%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .sixth1-1 {
          padding: 20px;
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          h3 {
            font-size: 1.25rem;
            line-height: 1.4;
            text-align: center;
          }
          p {
            text-align: center;
            margin-top: 10px;
            color: #ccc;
            font-size: 1.25rem;
          }
          ul {
            padding-top: 20px;
            font-size: 18px;
            li {
              text-align: center;
              margin-top: 10px;
            }
          }
          .btns {
            margin-top: 20px;
            text-align: center;
          }
        }
        .sixth1-1:hover {
          color: #fff;
          background-color: #04040e;
          box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
          transform: translate(0, -10px);
          transition: all ease 0.5s;
        }
      }
    }
    .seventh {
      width: 100%;
      margin-top: 40px;
      h1 {
        text-align: center;
      }
      .seventh1 {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
      .seventh1Left {
        width: 40%;
        .seventh1Left1 {
          margin-top: 40px;
          padding: 10px;
          box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
          h3 {
            margin-bottom: 15px;
            color: #e21f36;
          }
        }
        .seventh1Left1:hover {
          box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
          transform: translate(0, -10px);
          transition: all ease 0.5s;
        }
      }
      .seventh1Right {
        width: 30%;
        img {
          width: 500px;
          height: 645px;
        }
      }
    }
    .eighth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        text-align: center;
      }
      .warp {
        width: 255px * 4;
        height: 135px;
        margin: 40px auto;
        overflow: hidden;
        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;
          &.ul-item {
            display: flex;
            .li-item {
              width: 240px;
              height: 135px;
              margin-right: 50px;
              line-height: 140px;
              color: #fff;
              text-align: center;
              font-size: 1.875rem;
              img {
                width: 200px;
                height: 77px;
              }
            }
          }
        }
      }
    }

    .form-layer {
      width: 100%;
    }
    #map {
      margin-top: 30px;
      width: 100%;
      height: 300px;
      border: 1px solid gray;
      box-sizing: border-box;
      overflow: hidden;
    }
    /deep/ .el-dialog {
      min-width: 550px;
    }
    /deep/ .el-dialog__body {
      padding: 10px;
    }
  }
  .container {
    flex: 1;
  }
  /deep/.el-footer {
    padding: 0px !important;
  }
  .lasts {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(-1deg, #3c4255 0, #2a2f40 98%);
    padding: 10px 10px;
    h2 {
      font-size: 2rem;
      color: #fff;
      padding: 10px 0;
      text-align: center;
      margin: 30px 0;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
    a:hover {
      color: #286ea7;
      text-decoration: none;
    }
    .lastsTop {
      width: 100%;
      display: flex;
      justify-content: center;
      .lastsTop1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size: 1vw;
          line-height: 2.2;
          color: #ffffff;
        }
      }
      .lastsTop3 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size: 1vw;
          line-height: 2.2;
          color: #ffffff;
        }
      }
      .lastsTop2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size: 1vw;
          line-height: 2.2;
          color: #ffffff;
        }
      }
      .lastsTop6 {
        width: 12%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size: 1vw;
          line-height: 2.2;
          color: #ffffff;
        }
        img {
          width: 5rem;
          height: 5rem;
          margin-top: 5px;
        }
      }
    }
    .lastsTop7 {
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.7rem;
        margin-top: 10px;
        padding-bottom: 10px;
        margin-right: 10px;
        li {
          padding: 0 5px;
          border-right: 1px solid #ccc;
        }
      }
    }
    .lastsMiddle {
      color: #fff;
      border-top: 3px solid #79797a;
      padding-top: 8px;
      text-align: center;
      font-size: 12px;
      a {
        color: #fff;
      }
      span {
        cursor: pointer;
      }
    }
  }
}
// @media only screen and (max-width: 600px) {
//   .el-menu {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
//   .el-menu-item {
//     text-align: center;
//   }
// }

// @media only screen and (min-width: 600px) and (max-width: 900px) {
//   .el-menu {
//     flex-wrap: wrap;
//   }
//   .el-menu-item {
//     width: 50%;
//   }
// }
</style>
